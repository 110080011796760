import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useSettings } from 'contexts/SettingContext';
import useQuickAddToCartTracking from 'hooks/tracking/useQuickAddToCartTracking';
import getShopifyData from 'lib/shopify/getShopifyData';
import { useAddItemsToCart } from 'hooks';
import { useRouter } from 'next/router';
import useProductAddedTracking from 'hooks/tracking/useProductAddedTracking';
import { getShopifyGid } from 'lib/shopify/shopifyGid';
import { formatPrice } from 'lib/utils/helpers';
import {
  getSelectedSalonNo,
  getStorageCustomerSalonNo,
  formatPriceByStoreLocale,
} from 'lib/shopify';
import styles from './quickCartSection.module.scss';
import Icon from '../../../shared/icon';

const QuickCartSection = ({
  resources,
  productData,
  itemIndex,
  pageTitle,
  price,
  compareAtPrice,
  locale,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const addItems = useAddItemsToCart();
  const { addProduct, setSaveItemSelectionList } = useSettings();
  const { outOfStock: outOfStockResource } = resources.productpage;
  const { quickAddToCart, quickAddingToCart } = resources.secondpartproductpage;
  const {
    freeItem,
    amountOfProductsToGetFreeItem,
    promoCollectionName,
    shopifyTagsToExcludeFromPromo,
  } = resources.secondpartsettings;
  const salonNo = getStorageCustomerSalonNo(locale);
  const selectedSalonNo = getSelectedSalonNo(locale);
  const router = useRouter();

  const outOfStock = useMemo(() => {
    if (productData.type === 'PageProduct') {
      return productData?.shopifyData?.variants?.[0]?.outOfStock;
    }
    if (productData.type === 'PageProductBundle') {
      return productData.products.some(
        product => !!product?.shopifyData?.variants?.[0]?.outOfStock
      );
    }
    return true;
  }, [productData]);

  const handleAddToCart = async () => {
    setIsLoading(true);

    let shopifyIDs = [];
    if (productData.type === 'PageProductBundle') {
      shopifyIDs = productData.products.reduce(
        (rez, prod) => [
          ...rez,
          `gid://shopify/Product/${prod.shopifyData?.variants[0].product_id}`,
        ],
        []
      );
    } else {
      shopifyIDs.push(`gid://shopify/Product/${productData.shopifyData.variants[0].product_id}`);
    }

    const actualShopifyProducts = await getShopifyData({ productIds: shopifyIDs, locale });

    const allProducts = actualShopifyProducts.reduce((prev, shopifyProduct) => {
      const relatedProducts = shopifyProduct.relatedProducts || [];
      return [...prev, shopifyProduct.variantId, ...relatedProducts];
    }, []);

    const productsDataForCheckout = [...new Set(allProducts)].map(id => ({
      variantId: id,
      quantity: 1,
    }));

    await addItems(productsDataForCheckout, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });

    const productsArr =
      productData.type === 'PageProductBundle' ? productData.products : [productData];

    actualShopifyProducts.forEach((prod, i) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuickAddToCartTracking({
        product: {
          name: prod.productTitle,
          id: productsArr[i].shopifyData.variants[0]?.product_id,
          productSpecs: productsArr[i]?.productSpecs,
          price: prod.productPrice,
          index: itemIndex,
          quantity: 1,
        },
        list: `Category: ${pageTitle}`,
        setSaveItemSelectionList,
        locale,
      });
      // Universal Analytics
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductAddedTracking({
        affiliatedSalonNo: salonNo,
        selectedSalonNo,
        product: {
          name: prod.productTitle,
          id: productsArr[i].shopifyData.variants[0]?.product_id,
          brand: 'Keune',
          category: productsArr[i]?.productSpecs?.find(
            ({ type }) =>
              type === 'Producttype' || type === 'Type de produit' || type === 'Product type'
          )?.value,
          price: prod.productPrice,
          discount:
            prod.productCompareAtPrice !== null
              ? prod.productCompareAtPrice - prod.productPrice
              : null,
          quantity: 1,
        },
        location: { action: router },
      });

      if (!prod.isFreeItem) {
        addProduct({
          title: prod.productTitle,
          slug: prod.productHandle,
          variantId: getShopifyGid('ProductVariant', productsArr[i].shopifyData.variants[0].id),
        });
      }
    });

    setIsLoading(false);
  };

  const isEveryBundleProductHasShopifyData =
    productData.type === 'PageProductBundle' && productData?.products.every(p => p.shopifyData);

  if (productData.shopifyData || isEveryBundleProductHasShopifyData) {
    return (
      <>
        <div className={classNames(styles.cartSection)}>
          <button
            type="button"
            disabled={outOfStock}
            className={styles.addToCartButton}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              handleAddToCart();
            }}
          >
            {!outOfStock && <Icon type="cart" className={styles.cartIcon} />}
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading
              ? quickAddingToCart?.text
              : outOfStock
              ? outOfStockResource?.text
              : quickAddToCart?.text}
            <span>-</span>
            <span className={styles.productPrice}>
              {compareAtPrice ? (
                <s>
                  {formatPriceByStoreLocale({
                    locale,
                    price: formatPrice(compareAtPrice),
                  })}
                </s>
              ) : (
                formatPriceByStoreLocale({
                  locale,
                  price: formatPrice(price),
                })
              )}
            </span>
            {compareAtPrice && (
              <span className={styles.salesPrice}>
                {formatPriceByStoreLocale({
                  locale,
                  price: formatPrice(price),
                })}
              </span>
            )}
          </button>
        </div>
      </>
    );
  }

  return null;
};

export default QuickCartSection;
