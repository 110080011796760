export const VARIANT_QUERY = `
  availableForSale
  id
  title
  image {
    transformedSrc(preferredContentType: WEBP, maxWidth: 190)
  }
  selectedOptions {
    name
    value
  }
  priceV2 {
    amount
    currencyCode
  }
  compareAtPriceV2 {
    amount
    currencyCode
  }
  unitPrice {
    amount
    currencyCode
  }
  unitPriceMeasurement {
    referenceValue
    referenceUnit
  }
  product {
    id
    title
    tags
    productType
    vendor
    handle
    relatedProducts: metafield(namespace: "my_fields", key: "related_products") {
      value
    }
    collections(first: 2) {
    edges {
      node {
        id
        title
      }
    }
  }
  }
  sku
  weight
  weightUnit
`;

export const CHECKOUT_QUERY = `
  id
  note
  lineItemsSubtotalPrice {
    amount
  }
  totalPriceV2 {
    amount
  }
  customAttributes {
    key
    value
  }
  lineItems(first: 250) {
    edges {
      node {
        id
        title
        customAttributes {
          key
          value
        }
        discountAllocations {
          allocatedAmount {
              amount
              currencyCode
          }
          discountApplication {
            ... on AutomaticDiscountApplication {
              title
            }
          }
        }
        quantity
        variant {
          ${VARIANT_QUERY}
        }
      }
    }
  }
  currencyCode
  orderStatusUrl
  webUrl
  completedAt
`;

export const CART_QUERY = `
  id
  attributes {
    key
    value
  }
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
  }
  lines(first: 250) {
    edges {
      node {
        id
        merchandise {
          ... on ProductVariant {
            ${VARIANT_QUERY}
          }
        }
        quantity
        attributes {
          key
          value
        }
        cost {
          amountPerQuantity {
            amount
            currencyCode
          }
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        sellingPlanAllocation {
          sellingPlan {
            id
            name
            options {
              name
              value
            }
            priceAdjustments {
              adjustmentValue {
                ... on SellingPlanFixedAmountPriceAdjustment {
                  adjustmentAmount {
                    amount
                    currencyCode
                  }
                }
                ... on SellingPlanPercentagePriceAdjustment {
                  adjustmentPercentage
                }
                ... on SellingPlanFixedPriceAdjustment {
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  createdAt
  updatedAt
  checkoutUrl
`;

export const PRODUCT_MEDIA_QUERY = `
query getProductMedia($id: ID!) {
  product(id: $id) {
    title
    media (first: 5) {
      edges {
        node {
          ... on MediaImage {
            id
            image {
              originalSrc
              altText
            }
          }
        }
      }
    }
  }
}
`;

export const PRODUCT_THUMBNAIL_MUTATION = `
mutation productCreateMedia($media: [CreateMediaInput!]!, $productId: ID!) {
  productCreateMedia(media: $media, productId: $productId) {
    media {
      mediaContentType
      status
    }
    product {
      id
      handle
    }
  }
}
`;

export const PRODUCT_IMAGES_DELETE_MUTATION = `
mutation productDeleteMedia($mediaIds: [ID!]!, $productId: ID!) {
  productDeleteMedia(mediaIds: $mediaIds, productId: $productId) {
    deletedMediaIds
    deletedProductImageIds
    product {
      title
    }
  }
}
`;

export const PRODUCT_DATA_QUERY = `
query getProductsByIds($ids: [ID!]!) {
  nodes(ids: $ids) {
    ... on Product {
    title
    id
    handle
    productType
    tags
    productVendor: metafield(namespace: "my_fields", key: "collection_name") {
      value
    }
    relatedProducts: metafield(namespace: "my_fields", key: "related_products") {
      value
    }
    salePrice: metafield(namespace: "custom", key: "sale_price") {
      value
    }
    variants (first: 1) {
      edges {
        node {
          id
          sku
          price{
            amount
            currencyCode
          }
          compareAtPrice{
            amount
            currencyCode
          }
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            referenceValue
            referenceUnit
          }
          }
        }
      }
    }
  }
}
`;

export const PRODUCTS_QUERY = `  
query getAllProducts {
  products(first: 200) {
    edges {
      node {
        variants (first: 1) {
          edges {
            node {
              id
            }
          }
        }
        title
      }
    }
  }
}`;
